import React, { useEffect, useContext, useState } from 'react';
import useTranslations from "../../utils/useTranslations"
import { FaWindows } from 'react-icons/fa';
import Button from "../../components/button"
import Logo from "../../components/logo"
import btwebScreenshot from "../../images/downloads/btweb-windows-screen.png"
import btwebScreenshot2x from "../../images/downloads/btweb-windows-screen@2x.png"
import btclassicScreenshot from "../../images/downloads/btclassic-windows-screen.jpg"
import btclassicScreenshot2x from "../../images/downloads/btclassic-windows-screen@2x.jpg"
import btclassicScreenshot3x from "../../images/downloads/btclassic-windows-screen@3x.jpg"

import arrowBlack from "../../images/icons/arrow-black.svg"
import LocalizedLink from "../../components/localizedLink"
import { CompatibilityContext } from "../../components/compatibilityContext";
import { getPromoLinks, promoPrices, getShowPromoDiscount } from '../../components/promo'
import {WebPro, WebProVpn} from "../../components/cartLinks";


const PlatformsWindows = ({ pageContext: {text, locale} }) => {
const t = useTranslations(text)
  
  	const compatibilityHandler = useContext(CompatibilityContext)
	function handleClick(e, action, product, link){
		compatibilityHandler(e, action, product, ["Win"], link)
	}

	const webLink = "/products/win/bittorrent-web-compare/"
	const classicLink = "/products/win/bittorrent-classic-compare/"
	var webProLink          = WebPro.webWin
	var webProVPNLink       = WebProVpn.webWin
	var classicProLink      = "https://store.bittorrent.com/849/purl-btproweb?x-source=classicpro-platforms";
	const classicAdFreeLink = "https://store.bittorrent.com/849/purl-btafwebw?x-source=classicadfree-platforms";
	var classicProVPNLink   = "https://store.bittorrent.com/849/purl-btwebprovpn6995?x-source=classicprovpn-platforms";


	var proVpnPrice = "$69.95"
	var proPrice = "$19.95"
	var adFreePrice = "$4.95"


	const [proVpnPrice2, setproVpnPrice2] = useState(proVpnPrice)
	const [proPrice2, setproPrice2] = useState(proPrice)
	const [adFreePrice2, setadFreePrice2] = useState(adFreePrice)


	const [webProLink2, setwebProLink2] = useState(webProLink)
	const [webProVPNLink2, setwebProVPNLink2] = useState(webProVPNLink)
	const [classicProVPNLink2, setclassicProVPNLink2] = useState(classicProVPNLink)
	const [classicProLink2, setclassicProLink2] = useState(classicProLink)
	const [classicAdFreeLink2, setclassicAdFreeLink2] = useState(classicAdFreeLink)

	const showPromoDiscount = getShowPromoDiscount();
	const showProVpnPromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.proVpn;
	const showProPromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.pro;
	const showAdFreePromo = typeof showPromoDiscount === 'boolean' ? showPromoDiscount : showPromoDiscount.adFree;

	useEffect(() => {
		if (showProVpnPromo) {
			let newLinks = getPromoLinks({webProVPNLink, classicProVPNLink});
			setwebProVPNLink2(newLinks.webProVPNLink);
			setclassicProVPNLink2(newLinks.classicProVPNLink);
		}
	}, [showProVpnPromo]);

	useEffect(() => {
		if (showProPromo) {
			let newLinks = getPromoLinks({webProLink, classicProLink});
			setwebProLink2(newLinks.webProLink);
			setclassicProLink2(newLinks.classicProLink);
		}
	}, [showProPromo]);

	useEffect(() => {
		if (showAdFreePromo) {
			let newLinks = getPromoLinks({classicAdFreeLink});
			setclassicAdFreeLink2(newLinks.classicAdFreeLink);
		}
	}, [showAdFreePromo]);


  	return (
		<div className="container my-5 pt-5">
			<div className="row mb-5">
				<div className="col-12 text-center text-md-left">
					<h1><FaWindows className="mb-2" />  <span className="ml-2 d-inline-block">{t("BitTorrent Products for Windows")}</span></h1>
				</div>
			</div>

			<p className="text-size-32 font-weight-bold">
				{t(`Free Torrent Clients for Windows`)}
			</p>
			<div className="row">
				<div className="col-12 col-md-7 text-center text-md-left">
					<p className="platforms-section-description">
						{t(`Our free torrent software for Windows is ideal for new and experienced torrenters. Learn more about our web and desktop torrent clients to decide which is best for you.`)}
					</p>
				</div>
			</div>

			<div className="row mt-5">
				<div className="col-md-7 order-2 order-md-1 text-center text-md-left">

					<div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<Logo color="black" tag="h2" productName="Web" className="text-center text-md-left mb-5 mb-md-0" fsMax={22}/>
					</div>

					<p className="platforms-section-description mt-0 mt-md-4">
						{t(`Download the most trusted web torrent client for Windows. BitTorrent Web is a simple online torrent client that makes it easy to download and play torrents inside your favorite browser. BitTorrent Web is the best product to stream torrent files.`)}
					</p>
					<Button href={webLink} id="win-platforms-webfree" className="btn-primary button-filled text-spacing-1 text-decoration-none">{t("Free Download")}</Button>
	              	<div className="d-block d-md-inline-block mt-4 mt-md-0">
						<LocalizedLink 
							id="platforms-windows-btweb-learnmore"
							className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more ml-3" 
							to="/products/win/bittorrent-web-free">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
					</div>
				</div>
				<div className="col-md-5 order-1 order-md-2 mb-4 mb-md-0 text-center text-md-right">
					<img src={btwebScreenshot} srcSet={`${btwebScreenshot2x} 2x`} alt="BitTorrent Web Screenshot" className="img-fluid w-100"></img>
	                <div style={{fontSize: '10px'}} className="mt-2 text-center"><a id="platforms-win-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
				</div>
				<div className="col-12 order-3 mt-5">
					<hr className="platforms-hr-border"/>
				</div>
			</div>

			<div className="row pb-5 my-5">
				<div className="col-md-7 order-2 order-md-1 text-center text-md-left">


					<div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center">
		              	<Logo color="black" tag="h2" productName="Classic" className="text-center text-md-left mb-5 mb-md-0" fsMax={22}/>
					</div>

					<p className="platforms-section-description mt-0 mt-md-4">
						{t(`Get the original BitTorrent desktop app for Windows. BitTorrent Classic is ideal for fast, bulk torrent downloading and comes with advanced customization for experts that want the most out of a torrent desktop app.`)}
					</p>
					<Button href={classicLink} id="win-platforms-classicfree" className="btn-primary button-filled text-spacing-1">{t("Free Download")}</Button>
	              	<div className="d-block d-md-inline-block mt-4 mt-md-0">
						<LocalizedLink
							id="platforms-windows-classic-learnmore" 
							className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more ml-3" 
							to="/products/win/bittorrent-classic-free">

						{t(`Learn more`)} 

						</LocalizedLink>
						<img src={arrowBlack} className="ml-2 feature-list-arrow-black" alt="learn more"></img>
	              </div>
				</div>
				<div className="col-md-5 order-1 order-md-2 mb-4 mb-md-0 text-center text-md-right">
					<img src={btclassicScreenshot} srcSet={`${btclassicScreenshot2x} 2x, ${btclassicScreenshot3x} 3x`} alt="BitTorrent Classic Screenshot" className="img-fluid w-100"></img>
				</div>
			</div>


			<p className="text-size-32 font-weight-bold">
				{t(`Premium Windows Torrent Downloaders`)}
			</p>
			<div className="row">
				<div className="col-12 text-center text-md-left">
					<p className="platforms-section-description">
						{t(`Our premium Windows torrent downloaders are packed with features to help you download safely and with fewer distractions. Eliminate ads, automatically scan for malware and viruses, convert files and more with our premium torrent clients for Windows.`)}
					</p>
				</div>
			</div>

			<PaidProduct
				productName="Web Pro"
				price={proPrice2}
				cta={t(`Buy Now`)}
				link={webProLink2}
				id="win-platforms-webpro"
				onClick={(e) => {handleClick(e, "buying", "Classic", webProLink2)}}
				hasPromo={showProPromo}
				>
				<p className="platforms-section-description">{t(`Download and stream torrent files in a few steps, all within your favorite browser. Pro includes automatic protection from threats to keep your PC safe and a safe torrent scanner that provides helpful torrent info, including file size, resolution, seeds and number of leeches.`)}</p>
			</PaidProduct>
			<PaidProduct
				productName="Web Pro+VPN"
				price={proVpnPrice2}
				cta={t(`Buy Now`)}
				link={webProVPNLink2}
				id="win-platforms-webprovpn"
				onClick={(e) => {handleClick(e, "buying", "Classic", webProVPNLink2)}}
				hasPromo={showProVpnPromo}
				>
				<p className="platforms-section-description">{t(`Enjoy all the features of BitTorrent Web Pro including one-year of CyberGhost VPN on up to 5 devices. This premium VPN helps you prevent online tracking, be more private online, and protect your wifi connection.`)}</p>
			</PaidProduct>
			<PaidProduct
				productName="Classic Pro"
				price={proPrice2}
				cta={t(`Buy Now`)}
				link={classicProLink2}
				id="win-platforms-classicpro"
				onClick={(e) => {handleClick(e, "buying", "Classic", classicProLink2)}}
				hasPromo={showProPromo}
				>
				<p className="platforms-section-description">{t(`Pro takes the best BitTorrent desktop app and adds several features including automatic protection from malware and viruses, no advertisements, a media player, and a built-in file converter.`)}</p>
			</PaidProduct>
			<PaidProduct
				productName="Classic Ad-Free"
				price={adFreePrice2}
				cta={t(`Buy Now`)}
				link={classicAdFreeLink2}
				id="win-platforms-classicaf"
				onClick={(e) => {handleClick(e, "buying", "Classic", classicAdFreeLink2)}}
				hasPromo={showAdFreePromo}
				>
				<p className="platforms-section-description">{t(`Download your favorite BitTorrent desktop app for Windows without ads or distractions. This lightweight torrent client for Windows helps you download torrents in bulk, use a scheduler to download later, automatically download from RSS feeds, assign more bandwidth to specific torrents to download faster, and much more.`)}</p>
			</PaidProduct>
			<PaidProduct
				productName="Classic Pro+VPN"
				price={proVpnPrice2}
				cta={t(`Buy Now`)}
				link={classicProVPNLink2}
				id="win-platforms-classicprovpn"
				onClick={(e) => {handleClick(e, "buying", "Classic", classicProVPNLink2)}}
				hasPromo={showProVpnPromo}
				>
				<p className="platforms-section-description">{t(`Enjoy all the features of the popular BitTorrent Classic Pro torrent client for Windows, including one-year of CyberGhost VPN on up to 5 devices. CyberGhost enables you to prevent online tracking, protect your wifi connection, and be more private online. `)}</p>
			</PaidProduct>
			
		</div>
 	)
}



export default PlatformsWindows

const PaidProduct = (props) => {
	const t = useTranslations(props.text)
	const promoPercent = promoPrices.percent;

	return(
		<div className="mt-3 pb-3">
			<div className="d-flex justify-content-between mb-3 align-items-center flex-column flex-md-row">
				<div className="d-inline-block text-size-20"><span className="font-weight-bold bt-font">BitTorrent</span> {props.productName}</div>
				<div className="d-inline-block flex-shrink-0">
					{props.hasPromo &&
						<span className="text-danger text-uppercase">{promoPercent} {t("Off")} &nbsp;</span>
					}
					<span className={props.hasPromo ? 'text-decoration-line-through' : ''}>{props.price}</span>
		              <a
		              	onClick={props.onClick}
		              	href={props.link}
		              	id={props.id}
		                className="text-dark text-decoration-none feature-list-learn-more font-weight-normal index-mobile-learn-more" 
		                >

		               <span className="font-weight-bold"> {props.cta}</span> 

		              </a>
		              <div className="ml-1 feature-list-arrow-black d-inline-block font-weight-bold align-middle" alt="learn more">{`>`}</div>
				</div>
			</div>
			{props.children}
		</div>
		)
}